.page-my-box {
  width: 100%;
  padding: 90px 0;

  .page-my-title {
    text-align: center;
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
  }

  .page-my-data {
    width: 900px;
    margin: 40px auto 0;
    padding: 20px;
    border-radius: 30px;
    box-sizing: border-box;
    border: 1px solid #202023;
    background-color: rgba(14, 14, 15, 0.6);
    /* 模糊 */
    backdrop-filter: blur(15px);
    @media screen and (max-width: 740px) {
      width:calc(100% - 40px);
      border-radius: 20px;
      padding: 0px;
     }
    .page-my-data-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 80px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 20px;

      &:hover {
        background-color: #1c1c1f;
      }
      @media screen and (max-width: 740px) {
        flex-direction: column;
        padding:10px;
        gap: 10px;
       }

      .page-my-data-item-left {
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 740px) {
          justify-content: space-between;
          width: 100%;
         }
         &-domains {
          display: flex;
          align-items: center;
          justify-content: center;
         }
        .page-my-data-item-left-logo {
          width: 24px;
          height: 24px;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .page-my-data-item-left-name {
          color: #ffffff;
          font-weight: 400;
          margin-left: 10px;
        }

        .page-my-data-item-left-copy {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          cursor: pointer;

          svg g path {
            fill: #b6bbb4;
          }
        }

        .page-my-data-item-left-set {
          display: flex;
          align-items: center;
          margin-left: 30px;
          padding: 6px 15px;
          border-radius: 22px;
          font-size: 12px;
          font-weight: 400;
          color: #d1e67d;
          background-color: rgba(209, 230, 125, 0.1);
          cursor: pointer;
        }

        .page-my-data-item-left-setting {
          padding: 6px 15px;
          color: #676968;
          cursor: auto;
          background-color: #202023;
        }
      }
      .page-my-data-item-date{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        .page-my-data-item-right-label {
          color: #676968;
          font-weight: 400;
        }

        .page-my-data-item-right-time {
          color: #b6bbb4;
          font-weight: 400;
          margin-left: 10px;
        }
      }
      .page-my-data-item-right {
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 740px) {
          justify-content: space-between;
          width: 100%;
         }
        .page-my-data-item-right-label {
          color: #676968;
          font-weight: 400;
        }

        .page-my-data-item-right-time {
          color: #b6bbb4;
          font-weight: 400;
          margin-left: 10px;
        }

        .page-my-data-item-right-link {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          
          @media screen and (min-width: 740px) {
            justify-content: center;
            margin-left: 10px;
          }
          cursor: pointer;
        }

        .page-my-data-item-right-detail {
          cursor: pointer;
          color: #d1e67d;
          font-size: 12px;
          font-weight: 400;
          padding: 6px 10px;
          margin-left: 10px;
        }
      }
    }
  }

  .page-my-no-data {
    text-align: center;
    margin-top: 40px;
    padding: 40px 20px;

    div {
      width: 120px;
      height: 120px;
      margin: 0 auto;
    }

    p {
      margin: 23px 0 0;
      color: #676968;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .page-my-not-online {
    text-align: center;
    margin-top: 40px;
    padding: 40px 20px;

    div:nth-of-type(1) {
      width: 120px;
      height: 120px;
      margin: 0 auto;
    }

    p {
      margin: 23px 0 0;
      color: #676968;
      font-size: 14px;
      font-weight: 400;
    }

    div:nth-of-type(2) {
      width: max-content;
      height: 34px;
      margin: 20px auto 0;
      display: flex;
      padding: 8px 20px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #43532a;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      border-radius: 40px;
      background: #d1e67d;
    }
  }
}

.set-domain-modal {
  width: 600px;
  padding: 30px 20px 40px 20px;
  border-radius: 24px;
  box-sizing: border-box;
  @media screen and (max-width: 740px) {
    width:calc(100% - 40px);
    margin-top: 300px;
   }
  .set-domain-modal-content {
    width: 100%;

    .set-domain-modal-close-icon {
      display: flex;
      justify-content: flex-end;

      div {
        width: max-content;
        cursor: pointer;
      }
    }

    .set-domain-modal-title {
      font-size: 24px;
      font-weight: 600;
      color: #0e0e0f;
      text-align: center;
    }

    .set-domain-modal-desc {
      margin-top: 20px;
      color: #2f302f;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      padding: 0 30px;
    }

    .set-domain-modal-not-tips {
      margin: 20px auto 0;
      width: max-content;
    }

    .set-domain-modal-submit {
      width: 400px;
      padding: 16px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      background: #d1e67d;
      color: #43532a;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      margin: 20px auto 0;
      @media screen and (max-width: 740px) {
        width:calc(100% - 40px);
       }
    }
  }
}

@import "./style/theme.scss";
@import "./pages/pages.scss";
@import "./components/index.scss";

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  font-size: 14px;
  background: #0b0e14;
  color: #fff;
  // @include bodyBackground();
}
reach-portal {
  z-index: 2;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: max-content;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.root-box {
  width: 100%;
  min-height: 100vh;
  position: relative;
  transition: 1s color;
  &::after {
    content: "";
    will-change: transform;
    transform-style: preserve-3d;
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    top: var(--y);
    left: var(--x);
    border-radius: 50%;
    background-color: #d1e67d;
    transform: translate(-7px, -7px);
  }

  .root-center {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background: url("./assets/images/search-bgc.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
  }
}

.root-box-other-pages {
  background: #0e0e0f;

  &::after {
    background-color: transparent;
  }

  .root-center {
    background: #0e0e0f;
  }
}

.root-box-hide-after{

  &::after{
    background-color: transparent;
  }
}

input {
  color: var(--text-color-100);
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.svg-wrapper {
  display: flex;
  align-items: center;
  svg path {
    fill: var(--text-color-100);
  }
}

.svg-stroke-wrapper {
  display: flex;
  align-items: center;
  svg path {
    stroke: var(--text-color-100);
  }
}
.svg-des-wrapper {
  display: flex;
  align-items: center;
  svg path {
    fill: var(--text-color-50);
  }
}
.mir-svg-fill {
  fill: var(--text-color-100);
}
.mir-svg-stroke {
  stroke: var(--text-color-100);
}
.mir-des-svg-stroke {
  stroke: var(--text-color-100);
}
.flex-align-center {
  display: flex;
  align-items: center;
}

.wallet-logout-modal {
  color: #181616;
}
.wallet-modal {
  color: #181616;
}

.page-common-content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
}
.btn-loading,
.btn-disable {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.btn-loading-icon {
  position: relative;
  width: 14px;
  height: 14px;
  border: 2px solid #5bc781;
  border-bottom-color: #e1e2e5;
  border-radius: 100%;
  animation: turn infinite 1s linear;
  margin-right: 8px;
}

.blind-box {
  position: fixed;
  top: 130px;
  right: 80px;
  z-index: 1;
  width: 148px;
  height: 176px;
  border-radius: 30px;
  background: #d1e67d;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  @media screen and (max-width: 740px) {
    right:20px;
    top:70px;
    width: 88px;
    height: 101px;
    border-radius: 15px;
    padding: 5px;
   }
  .blind-box-logo {
    width: 128px;
    height: 128px;
    border-radius: 20px;
    overflow: hidden;
    margin: 0 auto;
    background-color: #0e0e0f;
   
    svg {
      width: 128px;
      height: 128px;
    }
    @media screen and (max-width: 740px) {
      width: 73px;
      height: 73px;
      border-radius: 10px;
      svg {
        width: 73px;
        height: 73px;
      }
    }
  }

  span {
    display: block;
    text-align: center;
    padding-top: 10px;
    color: #43532a;
    font-size: 12px;
    font-weight: 600;
    @media screen and (max-width: 740px) {
      padding-top: 5px;
      font-size: 10px;
    }
  }
}

.page-search-box {
  width: 100%;
  min-height: 100%;
  //position: relative;

  .page-search-content {
    width: 100%;
    min-height: 100%;
    padding: 145px 0;
    //background: url("../../assets/images/search-bgc.png") no-repeat;
    //background-size: 100% 100%;
    box-sizing: border-box;

    .page-search-title {
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      color: #eeeeee;
      padding-bottom: 40px;
      @media screen and (max-width: 740px) {
        font-size: 20px;
       }
    }

    .page-search-center {
      width: 600px;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      @media screen and (max-width: 740px) {
        font-size: 20px;
        width:calc(100% - 40px);
       }
    }

    .page-search-bar {
      width: 600px;
      margin: 30px auto 0;
      padding: 40px 20px 20px 20px;
      border-radius: 30px;
      color: #ffffff;
      border: 1px solid #202023;
      background-color: rgba(14, 14, 15, 0.7);
      @media screen and (max-width: 740px) {
        font-size: 20px;
        width:calc(100% - 40px);
       }
      .page-search-bar-title {
        display: flex;
        align-items: center;
        padding-left: 20px;

        span {
          margin-left: 10px;
          font-size: 18px;
          font-weight: 600;
        }
      }

      .page-search-bar-desc {
        margin-top: 20px;
        padding: 20px 22px 20px 22px;
        border-radius: 20px;
        background-color: #1c1c1f;

        div {
          display: flex;
          align-items: center;
          padding-bottom: 10px;
          span {
            margin-left: 12px;
          }
        }

        div:nth-of-type(1) {
          color: #d1e67d;
        }
        div:nth-of-type(2),
        div:nth-of-type(3) {
          color: #676968;
        }
      }
    }
  }
}

.page-name-detail {
  padding: 90px 0;
  @media screen and (max-width: 740px) {
    width:calc(100% - 40px);
   }
  .page-name-detail-content{
    width: 600px;
    margin: 0 auto;
    @media screen and (max-width: 740px) {
      width:100%;
     }
    .page-name-detail-cancel{
      width: 100%;

      .page-name-detail-cancel-btn{
        width: max-content;
        height: 34px;
        display: flex;
        padding: 8px 20px 8px 10px;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border-radius: 34px;
        background-color: #202023;
        cursor: pointer;
      }
    }

    .page-name-detail-title{
      width: 100%;
      box-sizing: border-box;
      margin-top: 48px;
      padding: 0 10px;
      display: flex;
      justify-content: space-between;

      .page-name-detail-title-name{
        display: flex;
        align-items: center;

        span{
          color: #ffffff;
          font-size: 24px;
          font-weight: 600;
          margin-right: 10px;
          @media screen and (max-width: 740px) {
            font-size: 16px;
          }
        }

        div{
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }

      .page-name-detail-title-btn{
        display: flex;
        padding: 8px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        background: #D1E67D;
        color: #43532A;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        @media screen and (max-width: 740px) {
          padding: 0px 20px;
        }
      }
    }

    .page-name-detail-desc{
      width: 100%;
      box-sizing: border-box;
      margin-top: 20px;
      padding: 20px;
      border-radius: 18px;
      border: 1px solid #202023;
      background: rgba(14, 14, 15, 0.6);
      /* 模糊 */
      backdrop-filter: blur(15px);

      .page-name-detail-desc-item{
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .page-name-detail-desc-item-label{
          color: #676968;
          font-size: 14px;
          font-weight: 400;
        }

        .page-name-detail-desc-item-val{
          display: flex;
          align-items: center;
          color: #B6BBB4;
          font-size: 14px;
          font-weight: 400;
          
          span:nth-of-type(1){
            margin-right: 10px;
            @media screen and (max-width: 600px) {
              width: 180px;
              overflow: hidden;
              text-overflow: ellipsis;
             }
             @media screen and (max-width: 400px) {
              width: 130px;
              overflow: hidden;
              text-overflow: ellipsis;
             }
          }

          span:nth-of-type(2),
          span:nth-of-type(3){
            width: 40px;
            height: 40px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover{
              background: rgba(61, 61, 68, 0.2);
            }
          }
        }
      }

      .mb10{
        margin-bottom: 10px;
      }

      .page-name-detail-desc-transfer-set{
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;

        div{
          display: flex;
          padding: 6px 15px;
          justify-content: center;
          align-items: center;
          border-radius: 22px;
          background:  rgba(209, 230, 125, 0.1);
          color: var(--unnamed, #D1E67D);
          font-size: 12px;
          font-weight: 400;
          cursor: pointer;
        }
      }

      div:last-child{
        margin-bottom: 0;
      }
    }
  }
}

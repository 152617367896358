.page-header {
  width: 100%;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  box-sizing: border-box;
  flex: 0 0 auto;
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: rgba(14, 14, 15, 0.3);
  backdrop-filter: blur(10px);
  @media screen and (max-width: 740px) {
    padding: 0 20px;
   }
  &-m-wallet{
    cursor: pointer;
  }
  &-m-menu{
    cursor: pointer;
    position: relative;
    &-list{
      position: absolute;
      left: 0;
      right: 0;
      top: 60px;
      bottom: 0;
      z-index: 2;
      background: rgba(14, 14, 15, 0.30);
      backdrop-filter: blur(10px);
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex-shrink: 0;
      &-item{
        display: flex;
        height: 44px;
        padding: 8px 13px 8px 8px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 40px;
        background: #202023;
        
      }
      &-my-domain{
        display: flex;
        width:100%;
        height: 44px;
        padding: 8px 20px;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        border-bottom: 1px solid rgba(209, 230, 125, 0.10);
        color: #D1E67D;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-transform: capitalize;
        svg {
          margin-right: 40px;
        }
      }
    }
  }
  .page-header-logo {
    display: flex;
    align-items: center;
    .page-header-search {
      margin-left: 50px;
    }
    .page-header-logo-icon {
      cursor: pointer;
    }
  }

  .page-header-right {
    display: flex;
    align-items: center;
  }

  .my-domain-btn,
  .white-list-btn {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 12px;
    font-weight: 400;
    color: #b6bbb4;
    margin-right: 10px;
    cursor: pointer;
  }

  .user-button {
    display: flex;
    height: 34px;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background-color: #d1e67d;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: #43532a;
    svg {
      margin-left: 10px;
    }
  }
  
}
.user-language {
  margin-left: 10px;
  display: flex;
  height: 34px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  color: #b3b4c8;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 40px;
  box-sizing: border-box;
  background-color: #202023;
  @media screen and (max-width: 740px) {
    margin-left: 0;
    justify-content: space-between;
    width: 100%;
   }
   &-m-left{
    display: flex;
    align-items: center;
   }
  span {
    margin: 0 5px;
  }
}
.scroll-chain-logo {
  display: flex;
  height: 34px;
  padding: 8px 20px 8px 3px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 40px;
  margin-right: 10px;
  background: #202023;
  user-select: none;
  cursor: pointer;
  img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
  span {
    margin-left: 10px;
    color: #b6bbb4;
    font-size: 12px;
    font-weight: 400;
  }
  &-m-left{
    display: flex;
    align-items: center;
   }
   @media screen and (max-width: 740px) {
    margin-left: 0;
    justify-content: space-between;
    width: 100%;
    padding: 8px 0px 8px 3px;
   }
}
.wallet-modal {
  width: 500px;
  border-radius: 24px;
  padding: 40px;
  @media screen and (max-width: 740px) {
    width: 300px;
    padding: 20px;
    // width: calc(100% - 80px);
    margin-top: 200px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .header-title {
    font-size: 24px;
    font-weight: 600;
    color: #0e0e0f;
  }
  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 0;
  }
  .wallet-item {
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 20px;
    background: #f0f1f2;

    &:hover {
      background: #e1e2e5;
    }

    &_title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_left {
        display: flex;
        align-items: center;
      }

      &_loading {
        position: relative;
        width: 14px;
        height: 14px;
        margin-right: 3px;
        border: 2px solid #5bc781;
        border-bottom-color: #e1e2e5;
        border-radius: 100%;
        animation: turn infinite 1s linear;
      }

      @keyframes turn {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    &_logo {
      width: 90px;
      height: 50px;

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      // background: #fff;
    }

    &_name {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 600;
    }

    &_des {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      padding: 5px 10px 5px 5px;
      border-radius: 10px;
      background: #efdada;

      &_left {
        display: flex;
        align-items: center;

        span {
          white-space: nowrap;
          display: flex;
          padding: 3px 10px;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          background: linear-gradient(
              135deg,
              #f2b074 0%,
              #db6f61 40.75%,
              #a53cf2 100%
            ),
            #d35454;
        }

        p {
          margin: 0 0 0 10px;
          color: #c14343;
          font-size: 12px;
          font-weight: 400;
        }
      }

      &_right {
        cursor: pointer;
        color: #4a3de6;
        font-size: 12px;
        font-weight: 500;
        text-decoration-line: underline;
        white-space: nowrap;
      }
    }

    &_uninjected {
      padding: 5px 10px;
      border-radius: 8px;
      background: #fff;
      font-size: 14px;
      color: #676968;
    }
  }
}
.wallet-logout-modal {
  position: absolute;
  width: 410px;
  top: 68px;
  right: 30px;
  margin: unset;
  border: 1px solid rgba(139, 138, 153, 0.1);
  box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.04);
  border-radius: 30px;
  padding: 0;
  background-color: #ffffff;
  overflow: hidden;
  padding-bottom: 30px;
  @media screen and (max-width: 740px) {
    width:calc(100% - 40px);
    top: 200px;
    right:20px;
  }
  &_wallet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 10px;
    background-color: #f0f1f2;

    &_info {
      display: flex;
      align-items: center;
      height: 54px;
      svg {
        width: 30px;
        height: 30px;
      }

      span {
        margin-left: 10px;
        color: #0e0e0f;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &_logout {
      display: flex;
      height: 34px;
      box-sizing: border-box;
      align-items: center;
      padding: 8px 20px;
      justify-content: center;
      gap: 5px;
      cursor: pointer;
      border-radius: 40px;
      background: #0e0e0f;
      color: #f0f1f2;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .wallet-logout-modal_wallet-address {
    min-height: 66px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;

    .wallet-logout-modal_wallet-address-logo {
      width: 30px;
      height: 30px;
      border-radius: 8px;
      overflow: hidden;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .wallet-logout-modal_wallet-address-val {
      color: #2f302f;
      font-size: 14px;
      font-weight: 500;
      margin: 0 10px;
    }

    .wallet-logout-modal_wallet-address-copy {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 4px;

      svg {
        width: 14px;
        height: 14px;
      }

      &:hover {
        background: rgba(61, 61, 68, 0.08);
      }
    }
  }
}

.user-language-modal {
  position: absolute;
  width: max-content;
  top: 68px;
  right: 30px;
  margin: unset;
  border: 1px solid var(--light-line-line-100, rgba(139, 138, 153, 0.1));
  box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 8px;
  background-color: #ffffff;
  overflow: hidden;
  @media screen and (max-width: 740px) {
    width: calc(100% - 60px);
    top: 214px;
    right: 20px;
    left: 20px;
   }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    box-sizing: border-box;
    color: #0e0e0f;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
    cursor: pointer;
    padding: 8px;

    &:hover {
      background: #f0f1f2;
    }
  }
}

.chain-modal {
  position: absolute;
  box-sizing: border-box;
  width: 260px;
  top: 68px;
  margin: unset;
  border-radius: 30px;
  padding: 15px 20px;
  background-color: #ffffff;
  overflow: hidden;
  color: #0e0e0f;
  @media screen and (max-width: 740px) {
   width:calc(100% - 40px);
   top: 144px;
   left: 20px;
   padding: 15px 10px;
  }
  .chain-info-item {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &_name {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
  }
}
.obdesc{
  margin-left: 10px;
  font-weight: 700;
  font-size: 12px;
}

.wallet-tutorial {
  // width: 781px;
  min-height: 619px;
  border-radius: 24px;
  padding: 30px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 53%, 0.4);
  }
  &:hover::-webkit-scrollbar-track {
    background: hsla(0, 0%, 53%, 0.1);
  }
  &::-webkit-scrollbar-corner {
    border-radius: 4px;
  }
  @media screen and (max-width: 740px) {
    width: 300px;
    padding: 20px;
    // width: calc(100% - 80px);
    margin-top: 200px;
  }
  .wt-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &-title {
      font-size: 24px;
      font-weight: 600;
      color: #0e0e0f;
    }
    &-close-icon {
      cursor: pointer;
      margin-left: auto;
    }
  }
  .wt-content-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:30px;
    &-title{
      color: #0E0E0F;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
    &-img{
      width: 821px;
    }
  }
}
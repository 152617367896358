
@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.circular-progress {
  position: relative;
  .circular-progress-svg {
    position: absolute;
    top:0;
    left: 0;
    stroke: var(--active-color-50);
  }
  .circular-progress-loading-svg {
    position: absolute;
    top:0;
    left: 0;
    stroke: $Primary-100;
    animation: loading 0.8s linear 0s infinite;
  }
  .circular-progress-loading-add-svg {
    position: absolute;
    top:0;
    left: 0;
    stroke: $Primary-100;
    transform: rotate(-90deg);
  }
}
.token-img-root {
  width: 32px;
  height: 32px;
  position: relative;
  &_tokenicon{
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: 100%;
    border-radius: 50%;
  }
  &_chainicon{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 12px;
    height: 12px;
    border: 1px solid var(--bg-color-100);;
    border-radius: 50%;
  }
}
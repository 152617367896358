.page-mystery-box {
  padding: 90px 0;
  @media screen and (max-width: 740px) {
    width: calc(100% - 40px);
  }
  .page-mystery-box-content {
    width: 1100px;
    margin: 0 auto;
    @media screen and (max-width: 740px) {
      width: 100%;
    }
    .page-mystery-box-switch {
      display: flex;
      padding: 5px;
      align-items: center;
      border-radius: 30px;
      border: 1px solid #2f302f;
      width: 152px;
      height: 30px;
      margin: 0 auto;
      .tab-item {
        color: #b6bbb4;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        text-transform: capitalize;
        width: 76px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        &-selected {
          color: #43532a;
          border-radius: 40px;
          background: #d1e67d;
        }
      }
    }
    .page-mystery-box-title {
      text-align: center;
      color: #ffffff;
      font-size: 24px;
      font-weight: 600;
    }

    .page-mystery-box-center {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 30px 0;
      box-sizing: border-box;

      .page-mystery-box-left {
        width: 400px;
        height: max-content;
        padding: 30px 20px;
        box-sizing: border-box;
        border-radius: 30px;
        border: 1px solid #202023;
        background: rgba(14, 14, 15, 0.6);
        /* 模糊 */
        backdrop-filter: blur(15px);
        @media screen and (max-width: 740px) {
          min-width: calc(100% - 40px);
          padding: 30px 0px;
        }
        .page-mystery-box-header {
          display: flex;
          color: #b6bbb4;
          /* 12 */
          font-size: 12px;
          font-weight: 400;

          > div {
            padding: 0 10px;
          }

          > div:nth-of-type(1) {
            width: 65px;
            text-align: center;
          }

          > div:nth-of-type(2) {
            flex: 1 1 auto;
            text-align: center;
          }

          .page-mystery-box-header-mark {
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
              margin-right: 4px;
            }
          }
        }

        .page-mystery-box-line {
          width: 100%;
          height: 1px;
          margin: 20px 0;
          background-color: #1c1c1f;
        }

        .page-mystery-box-body {
          width: 100%;
          height: 550px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 4px;
          }

          &:hover::-webkit-scrollbar-thumb {
            background: hsla(0, 0%, 53%, 0.4);
          }
          &:hover::-webkit-scrollbar-track {
            background: hsla(0, 0%, 53%, 0.1);
          }
          &::-webkit-scrollbar-corner {
            border-radius: 4px;
          }

          > div {
            margin-bottom: 20px;
          }

          > div:last-child {
            margin-bottom: 0;
          }

          .page-mystery-box-body-item {
            width: 100%;
            height: 35px;
            box-sizing: border-box;
            padding: 10px;
            display: flex;
            align-items: center;
            color: #ffffff;
            font-size: 14px;
            font-weight: 400;
            border-radius: 10px;

            &:hover {
              background-color: #1c1c1f;
            }

            div:nth-of-type(1) {
              width: 65px;
              text-align: center;
            }

            div:nth-of-type(2) {
              flex: 1 1 auto;
              text-align: center;
            }

            div:nth-of-type(3) {
              width: 80px;
              text-align: right;
            }

            .page-mystery-box-body-item-rankNo,
            .page-mystery-box-body-item-domain,
            .page-mystery-box-body-item-invites {
              color: #d1e67d;
            }
          }
          .page-mystery-box-body-item-my {
            color: #d1e67d;
          }
        }
      }

      .page-mystery-box-right {
        width: 660px;
        box-sizing: border-box;
        @media screen and (max-width: 740px) {
          width: 100%;
        }
        .page-mystery-box-right-invite {
          width: 100%;
          box-sizing: border-box;
          padding: 20px 10px 10px 10px;
          border-radius: 30px;
          border: 1px solid #1c1c1f;
          background: rgba(14, 14, 15, 0.6);
          /* 模糊 */
          backdrop-filter: blur(15px);

          .page-mystery-box-right-invite-desc {
            display: flex;
            justify-content: space-between;
            padding: 0 20px;

            > div {
              color: #b6bbb4;
              font-size: 12px;
              font-weight: 400;
            }
          }

          .page-mystery-box-right-invite-link {
            margin-top: 10px;
            padding: 10px 10px 10px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 20px;
            background: #1c1c1f;

            .page-mystery-box-right-invite-link-copy {
              padding-left: 12px;
              display: flex;
              align-items: center;

              span {
                // margin-right: 10px;
                color: #d1e67d;
                font-size: 12px;
                font-weight: 400;
              }

              div {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
            }

            &_title {
              white-space: nowrap;
            }
            &_inviteLink {
              word-break: break-all;
            }
          }
          .page-mystery-box-right-invite-login {
            padding: 8px 20px;
            border-radius: 40px;
            border: 1px solid var(--unnamed, #43532a);
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            color: #d1e67d;
            cursor: pointer;
          }
        }

        .page-mystery-box-right-step {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          padding: 40px 0 40px 70px;
          border-radius: 30px;
          border: 1px solid #1c1c1f;
          background: rgba(14, 14, 15, 0.6);
          @media screen and (max-width: 740px) {
            flex-direction: column;
            margin: 0 auto;
            padding: 10px 10px 20px 10px;
          }
          .page-mystery-box-right-step-icon {
            width: 205px;
            @media screen and (max-width: 740px) {
              margin: 0 auto;
            }
            .page-mystery-box-right-step-icon-num {
              // white-space: nowrap;
              margin-top: 30px;
              padding: 10px 15px;
              color: #b6bbb4;
              font-size: 12px;
              font-weight: 400;
              border-radius: 10px;
              background: #1c1c1f;
              @media screen and (max-width: 740px) {
                margin-bottom: 40px;
              }
            }
          }

          .page-mystery-box-right-step-desc {
            width: 338px;

            .page-mystery-box-right-step-desc-ol {
              padding: 0;
              margin: 0;
              counter-reset: order; /* 定义CSS计数器 */
              list-style: none;

              li {
                position: relative;

                .page-mystery-box-right-step-desc-ol-line {
                  position: absolute;
                  top: 0;
                  inset-inline-start: 15px;
                  width: 1px;
                  height: calc(100% - 43px);
                  padding: 38px 0 0;

                  &::after {
                    display: inline-block;
                    width: 1px;
                    height: 100%;
                    background: #d1e67d;
                    border-radius: 1px;
                    transition: background 0.3s;
                    content: "";
                  }
                }

                .page-mystery-box-right-step-desc-ol-item {
                  display: flex;
                  //align-items: center;
                  font-size: 16px;
                  font-weight: 600;
                  counter-increment: order;

                  &::before {
                    content: counter(order); /* 编号 */
                    width: 30px;
                    height: 30px;
                    display: flex;
                    flex-shrink: 0;
                    align-items: center;
                    justify-content: center;
                    color: #d1e67d;
                    font-size: 16px;
                    font-weight: 600;
                    margin-right: 10px;
                    border-radius: 50%;
                    border: 1px solid #d1e67d;
                  }

                  p {
                    width: calc(100% - 42px);
                    margin: 0;
                    min-height: 70px;

                    span {
                      display: block;
                    }

                    span:nth-of-type(1) {
                      color: #d1e67d;
                      font-size: 14px;
                      font-weight: 400;
                      display: block;
                    }

                    span:nth-of-type(2) {
                      color: #676968;
                      font-size: 12px;
                      font-weight: 400;
                      margin-top: 3px;
                    }
                  }
                }
              }

              li:not(:last-child)::after {
                //content: '';
                //display: flex;
                //width: 1px;
                //height: 30px;
                //background-color: #D1E67D;
                //margin-left: 15px;
              }
            }
          }
        }

        .page-mystery-box-right-list {
          width: 100%;
          box-sizing: border-box;
          margin-top: 20px;
          padding: 10px 10px 20px 10px;
          border-radius: 30px;
          border: 1px solid #202023;
          background: rgba(14, 14, 15, 0.6);
          /* 模糊 */
          backdrop-filter: blur(15px);

          .page-mystery-box-right-list-tabs {
            width: max-content;
            display: flex;
            padding: 5px;
            border-radius: 30px;
            border: 1px solid #2f302f;

            div {
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 88px;
              height: 30px;
              border-radius: 40px;
              color: #b6bbb4;
              font-size: 12px;
              font-weight: 400;
              background-color: transparent;
              cursor: pointer;
            }

            .page-mystery-box-right-list-tabs-active {
              background-color: #d1e67d;
              color: #43532a;
              font-size: 12px;
              font-weight: 600;
            }
          }

          .page-mystery-box-right-invite-list {
            width: 100%;

            .page-mystery-box-right-invite-list-header {
              width: 100%;
              box-sizing: border-box;
              padding: 0 10px 10px;
              display: flex;
              margin: 20px 0;
              border-bottom: 1px solid #1c1c1f;

              div {
                width: 25%;
                text-align: center;
                color: #b6bbb4;
                font-size: 12px;
                font-weight: 400;
              }

              div:nth-of-type(1) {
                text-align: left;
              }

              div:nth-of-type(4) {
                text-align: right;
              }
            }

            .page-mystery-box-right-invite-list-body {
              width: 100%;
              max-height: 400px;
              overflow-y: auto;

              &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
                background: transparent;
              }

              &::-webkit-scrollbar-thumb {
                background: transparent;
                border-radius: 4px;
              }

              &:hover::-webkit-scrollbar-thumb {
                background: hsla(0, 0%, 53%, 0.4);
              }
              &:hover::-webkit-scrollbar-track {
                background: hsla(0, 0%, 53%, 0.1);
              }
              &::-webkit-scrollbar-corner {
                border-radius: 4px;
              }

              .page-mystery-box-right-invite-list-item {
                width: 100%;
                display: flex;
                border-radius: 10px;

                &:hover {
                  background-color: #1c1c1f;
                }

                div {
                  width: 25%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 40px;
                  color: #ffffff;
                  font-size: 14px;
                  font-weight: 400;
                }

                div:nth-of-type(1) {
                  text-align: left;
                }

                div:nth-of-type(4) {
                  text-align: right;
                }
              }
            }

            .page-mystery-box-right-invite-list-no-data {
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              color: #676968;
            }
          }

          .page-mystery-box-right-box-list {
            width: 100%;

            .page-mystery-box-right-box-list-center {
              .page-mystery-box-right-box-list-center-title {
                padding: 20px 0;
                text-align: center;
                color: #d1e67d;
                font-size: 14px;
                font-weight: 400;
              }

              .page-mystery-box-right-box-list-wrap {
                display: flex;
                flex-wrap: wrap;

                div {
                  width: 140px;
                  height: 140px;
                  margin: 0 20px 20px 0;

                  svg {
                    width: 140px;
                    height: 140px;
                  }
                }

                div:nth-of-type(4n) {
                  margin: 0 0 20px 0;
                }
              }
            }
          }

          .page-mystery-box-right-box-list-no-data {
            padding-top: 20px;

            div:nth-of-type(1) {
              width: 200px;
              height: 200px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 auto;

              svg {
                width: 200px;
                height: 200px;
              }
              @media screen and (max-width: 740px) {
                width: 190px;
                height: 190px;
                svg {
                  width: 190px;
                  height: 190px;
                }
              }
            }

            div:nth-of-type(2) {
              width: max-content;
              margin: 30px auto 0;
              border-radius: 10px;
              padding: 10px 15px;
              background: #1c1c1f;
              color: #b6bbb4;
              font-size: 12px;
              font-weight: 400;
            }

            p {
              text-align: center;
              padding-top: 20px;
              margin: 0;
              color: #d1e67d;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .tooltip {
    position: relative;

    .page-mystery-box-header-tip {
      width: 18px;
      height: 18px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background: rgba(61, 61, 68, 0.4);
      }
    }

    .tip-container {
      width: max-content;
      display: none;
      position: absolute;
      top: -14px;
      left: 50%;
      transform: translate(-50%, -100%);
      background-color: #3e3d4d;
      color: #ffffff;
      border-radius: 8px;
      padding: 8px 12px;

      .tip-arrow {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 10px;
        height: 10px;
        background-color: #3e3d4d;
        color: #ffffff;
        transform: translate(-50%, 50%) rotate(-45deg);
      }
    }
    &:hover {
      .tip-container {
        display: block;
      }
    }
  }
}

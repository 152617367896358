.search-input-box {
  position: relative;
  &_medium_padding {
    padding-top: 10px;
  }
  &_medium {
    height: 34px;

    .search-result-open {
      // margin-top: 10px;
      padding: 15px 0 !important;
      border-radius: 15px !important;
    }
  }
  &_extraLarge {
    height: 56px;
  }
  .search-wrapper {
    display: flex;
    // width: 600px;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    background: #ffffff;
    backdrop-filter: blur(5px);
    padding: 0 20px;
    input {
      flex: 1 1;
      border: 0;
      height: 100%;
      text-align: left;
      border: none;
      outline: none;
      background: unset;
      font-size: 14px;

      &::-webkit-input-placeholder {
        color: #676968;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &_extraLarge {
      height: 56px;
      padding: 0 20px;
    }
    &_medium {
      width: 300px;
      height: 34px;
      padding: 0 10px;
      background-color: #0e0e0f;
      border: 1px solid #2f302f;

      &:hover {
        border: 1px solid #676968;
      }

      input {
        font-size: 12px;
        color: #ffffff;
        font-weight: 400;

        &::-webkit-input-placeholder {
          color: #2f302f;
          font-size: 12px;
          font-weight: 400;
        }
      }

      .search-input-clear {
        svg path {
          stroke: #b6bbb4;
        }
      }
    }
  }

  .search-wrapper_medium_active {
    border: 1px solid #d1e67d !important;

    svg path {
      fill: #b6bbb4;
    }
  }

  .result-open {
    border-radius: 30px 30px 0 0;
  }

  .search-input-clear {
    cursor: pointer;
  }

  .search-result-wrapper {
    background: #ffffff;
    border-radius: 0 0 30px 30px;
    opacity: 0;
    overflow: hidden;

    .result-item {
      display: flex;
      width: 100%;
      height: 56px;
      padding: 0 20px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;
      &:hover {
        background: #f0f1f2;
      }

      .result-item-label {
        color: #0e0e0f;
        font-weight: 400;
      }

      .result-item-status {
        display: flex;
        align-items: center;
        color: #0e0e0f;
        font-weight: 400;

        span {
          margin-right: 10px;
          display: flex;
          padding: 3px 10px;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
        }

        .success {
          background: rgba(125, 230, 162, 0.3);
          color: #4a9262;
          font-size: 12px;
          font-weight: 400;
        }

        .error {
          background: rgba(255, 134, 134, 0.2);
          color: #e06363;
          font-size: 12px;
          font-weight: 400;
        }

        .timeout {
          color: #e06363;
          font-size: 12px;
          font-weight: 400;
        }

        i {
          width: 24px;
          height: 24px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .result-item-status-loading {
        .result-item-status-loading-icon {
          position: relative;
          width: 14px;
          height: 14px;
          border: 2px solid #5bc781;
          border-bottom-color: #e1e2e5;
          border-radius: 100%;
          animation: turn infinite 1s linear;
        }
      }

      @keyframes turn {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    > div:nth-of-type(1) {
      border-left: 5px solid #7de6a2;
      background: #f0f1f2;
    }

    .result-item-active {
      background: #f0f1f2;
    }
    // transition: 0.35s all cubic-bezier(1, 0, 0.22, 1.6),
    //   0s border-color linear 0s, 0s width linear 0s;
  }
  .search-result-open {
    opacity: 1;
    padding-bottom: 30px;
    // transform: translateY(0px);
  }
}

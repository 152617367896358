$Primary-10: #695eea1a;
$Primary-20: #695eea33;
$Primary-30: #695eea4d;
$Primary-100: #695eea;
$Primary-Normal: #4a3de6;
$Primary-Normal-16: #4136ca;
$Primary-Normal-18: #3e33c1;

$Static-Text-Light: #ebedf5;
$Static-Text-Dark: #3e3d4d;

$Dark-Background-BG-100: #34343d;
$Dark-Background-BG-200: #2b2b33;
$Dark-Background-BG-300: #24242a;
$Dark-Background-BG-400: #1e1e23;
$Dark-Background-BG-500: #161619;
$Dark-Background-BG-600: #0d0d0f;
$Dark-Background-BG-alt: #24242a;
$Dark-Text-Text-50: #ebedf580;
$Dark-Text-Text-80: #ebedf5cc;
$Dark-Text-Text-100: #ebedf5;
$Dark-Text-Text-200: #fafbff;
$Dark-Line-Line-100: #8b8a991a;
$Dark-Line-Line-200: #8b8a994d;
$Dark-Active-8: #7d7a9914;
$Dark-Active-12: #7d7a991f;
$Dark-Active-16: #7d7a9929;
$Dark-Active-50: #7d7a9980;

$Light-Background-BG-100: #ffffff;
$Light-Background-BG-200: #fafafa;
$Light-Background-BG-300: #f5f5f7;
$Light-Background-BG-400: #ebebef;
$Light-Background-BG-500: #e2e2e8;
$Light-Background-BG-600: #dadae2;
$Light-Text-Text-50: #a9a8b2;
$Light-Text-Text-80: #8b8a99;
$Light-Text-Text-100: #3e3d4d;
$Light-Text-Text-200: #161619;
$Light-Line-Line-100: #8b8a991a;
$Light-Line-Line-200: #8b8a994d;
$Light-Active-8: #82818c14;
$Light-Active-12: #82818c1f;
$Light-Active-16: #82818c29;
$Light-Active-50: #8e8d9980;

$Other-Red-R50: #fdeeee;
$Other-Red-R75: #f7b8b8;
$Other-Red-R100: #f39a9a;
$Other-Red-R200: #ee6f6f;
$Other-Red-R300: #eb5151;
$Other-Red-R400: #a53939;
$Other-Red-R500: #8f3131;
$Other-Green-G50: #f0fbf9;
$Other-Green-G75: #c2ede6;
$Other-Green-G100: #a8e6db;
$Other-Green-G200: #83dbcc;
$Other-Green-G300: #69d4c1;
$Other-Green-G400: #4a9487;
$Other-Green-G500: #408176;
$Other-Yellow-Y50: #fef5ee;
$Other-Yellow-Y75: #fcd4b8;
$Other-Yellow-Y100: #fbc39b;
$Other-Yellow-Y200: #f9a970;
$Other-Yellow-Y300: #f89753;
$Other-Yellow-Y400: #ae6a3a;
$Other-Yellow-Y500: #975c33;
$Other-Blue-B50: #f3fbff;
$Other-Blue-B75: #cfefff;
$Other-Blue-B100: #bce8ff;
$Other-Blue-B200: #9fdeff;
$Other-Blue-B300: #8bd7ff;
$Other-Blue-B400: #6197b3;
$Other-Blue-B500: #55839c;
$Other-Pink-P50: #fff5f9;
$Other-Pink-P75: #ffd7e7;
$Other-Pink-P100: #ffc6dd;
$Other-Pink-P200: #ffaecf;
$Other-Pink-P300: #ff9dc5;
$Other-Pink-P400: #b36e8a;
$Other-Pink-P500: #9c6078;
.mili-drawer {
    position: fixed;
    z-index: 1001;
    width: 0%;
    height: 100%;
    transition: width 0s ease .3s, height 0s ease .3s;
    top: 0;
    overflow: hidden;
}


.mili-drawer-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: rgba(21, 20, 32, 0.7);;
    opacity: 0;
    transition: opacity .3s linear, height 0s ease .3s;
    pointer-events: none;
}
.mili-drawer-open {
    .mili-drawer-mask {
        height: 100%;
        opacity: 1;
        transition: none;
        animation: miliDrawerFadeIn .3s cubic-bezier(.23, 1, .32, 1);
        pointer-events: auto;
    }
}

.mili-drawer-content-wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform .3s cubic-bezier(.23,1,.32,1);
    // @include layered-background-color-2();
}

.mili-drawer-content {
    // padding: 18px 15px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar { width: 0 !important }
}

.mili-drawer-right{
    right: 0;
    .mili-drawer-content-wrapper {
        right: 0;
    }
}

.mili-drawer-left{
    left: 0;
    .mili-drawer-content-wrapper {
        left: 0;
    }
}
.mili-drawer-top .mili-drawer-content-wrapper {
    top: 0;
}
.mili-drawer-bottom .mili-drawer-content-wrapper {
    bottom: 0;
}

.mili-drawer-left, .mili-drawer-right {
    top: 0;
    width: 0%;
    height: 100%;
    .mili-drawer-open {
        width: 100%;
        transition: transform .3s cubic-bezier(.23,1,.32,1);
    }
}

.mili-drawer-left.mili-drawer-open, .mili-drawer-right.mili-drawer-open {
    width: 100%;
    transition: transform .3s cubic-bezier(.23,1,.32,1);
}

.mili-drawer-top, .mili-drawer-bottom {
    left: 0;
    width: 100%;
    height: 0%;
}

.mili-drawer-top.mili-drawer-open, .mili-drawer-bottom.mili-drawer-open {
    height: 100%;
    transition: transform .3s cubic-bezier(.23,1,.32,1);
}


.drawer-header-title {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    // @include text-color-1();
    .back-icon-close {
        cursor: pointer;
        font-size: 20px;
        width: 24px;
        height: 24px;
        border-radius: 8px;
        text-align: center;
        line-height: 24px;
        // @include text-color-1();
        // @include layered-background-color-3();
    }
    .title {
        margin-left: 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }
}

@keyframes miliDrawerFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.page-footer {
  width: 100%;
  // height: 95px;
  height: 40px;
  flex: 0 0 auto;
  padding: 20px 0;
  position: relative;
  z-index: 1;

  .page-footer-top {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 19px;
    box-sizing: border-box;
    // border-bottom: 1px solid rgba(81, 81, 92, 0.4);

    .page-footer-icon-box {
      width: max-content;
      display: flex;

      div {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: rgba(245, 247, 253, 0.04);
        margin-right: 10px;

        svg {
          path {
            fill: rgba(255, 255, 255, 0.7);
          }
        }

        &:hover {
          background-color: rgba(245, 247, 253, 0.12);

          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }

      div:last-child {
        margin-right: 0;
      }
    }
  }

  .page-footer-bottom {
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px 0 0;

    div {
      height: 15px;
      color: #676968;
      font-size: 12px;
      font-weight: 400;
      margin-left: 30px;

      &:hover {
        color: #b6bbb4;
      }
    }
  }
}

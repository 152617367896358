input[disabled] {
  cursor: not-allowed !important;
}

.checkbox-disabled {
  div,span{
    cursor:not-allowed !important;
  }
}

.checkbox-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  .checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    line-height: unset;
    cursor: pointer;
    display: flex;
    align-items: center;

    .checkbox {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: #000000d9;
      font-size: 14px;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum';
      position: relative;
      line-height: 1;
      white-space: nowrap;
      outline: none;
      cursor: pointer;

      .checkbox-input {
        position: absolute;
        inset: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        margin: 0;
      }

      .checkbox-inner {
        position: relative;
        display: block;
        width: 12px;
        height: 12px;
        direction: ltr;
        background-color: #ffffff;
        border: 1px solid #0E0E0F;
        border-radius: 4px;
        border-collapse: separate;
        transition: all 0.3s;

        &-disabled {
          background-color: #dadae2;
          cursor: not-allowed;
        }

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          display: table;
          width: 5px;
          height: 9px;
          border: 2px solid #fff;
          border-top: 0;
          border-left: 0;
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
          opacity: 0;
          transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
          opacity 0.1s;
          content: ' ';
        }
      }
    }

    .checkbox-checked {
      .checkbox-inner {
        background-color: #0E0E0F;
        border: 1px solid #0E0E0F;

        &::after {
          position: absolute;
          display: table;
          border: 2px solid #ffffff;
          border-top: 0;
          border-left: 0;
          opacity: 1;
          transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
          content: ' ';
          left: 18%;
          top: 8%;
          transform: rotate(45deg) scale(0.5) translate3d(-25%, -25%, 0);
        }
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      visibility: hidden;
      animation: antCheckboxEffect 0.36s ease-in-out;
      animation-fill-mode: backwards;
      content: '';
    }

    > span:last-child {
      font-weight: 400;
      font-size: 12px;
      margin-left: 10px;
      color: #0E0E0F;
    }
  }

  &.md {
    .checkbox-wrapper {
      .checkbox {
        top: 0;
        .checkbox-inner {
          height: 20px;
          width: 20px;
          border-color: #{text-color('default-200')};
          [dark-background='dark'] & {
            border-color: #{text-color('dark-200')};
          }
        }
      }

      .checkbox-checked {
        .checkbox-inner {
          height: 20px;
          width: 20px;
          &::after {
            left: 20%;
            width: 7px;
            height: 14px;
            top: 14%;
          }
        }
      }

      > span:last-child {
        margin-left: 15px;
        font-size: 14px;
      }
    }
  }
}

.name-set-modal {
  width: 600px;
  padding: 30px 20px 40px 20px;
  border-radius: 30px;
  background: #fff;
  box-sizing: border-box;
  @media screen and (max-width: 740px) {
    width:calc(100% - 40px);
    margin-top: 300px;
   }
  .name-set-content {
    width: 100%;

    .name-set-modal-close-icon {
      display: flex;
      justify-content: flex-end;
      height: 27px;

      div {
        width: max-content;
        cursor: pointer;
      }
    }

    .name-set-modal-title {
      font-size: 24px;
      font-weight: 600;
      color: #0e0e0f;
      text-align: center;
    }

    .name-set-modal-input {
      width: 100%;
      margin-top: 20px;
      border-radius: 18px;
      border: 1px solid #e6e9ef;
      overflow: hidden;

      input {
        box-sizing: border-box;
        border: none;
        width: 100%;
        outline: none;
        padding: 20px 10px 20px 20px;
        background-color: transparent;
        color: #0e0e0f;
        font-size: 14px;
        font-weight: 400;

        &::-webkit-input-placeholder {
          font-size: 14px;
          font-weight: 400;
          color: #0e0e0f;
        }
      }
    }

    .name-set-default-address {
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
      color: #676968;
      font-size: 12px;
      font-weight: 400;
    }

    .name-set-modal-submit-btn {
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn-div {
        width: 270px;
        height: 50px;
        border-radius: 40px;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .name-set-modal-cancel-btn {
        color: #676968;
        border: 1px solid #e6e9ef;
      }

      .name-set-modal-confirm-btn {
        color: #43532a;
        background: #d1e67d;
        border: 1px solid #d1e67d;
      }
      .name-set-modal-confirm-btn-loding {
        width: 100%;
      }
    }
  }
}

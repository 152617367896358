.page-register-success{
  width: 100%;
  padding: 170px 0;

  .page-register-success-icon{
    text-align: center;
  }

  .page-register-success-desc{
    text-align: center;
    padding-top: 20px;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
  }

  .page-register-success-btn{
    width: 410px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 60px;

    div{
      display: flex;
      width: 190px;
      height: 52px;
      justify-content: center;
      align-items: center;
      border-radius: 200px;
      cursor: pointer;
    }

    .page-register-home-btn{
      border: 1px solid #D1E67D;
      background: rgba(14, 14, 15, 0.6);
      color: #D1E67D;
      font-size: 14px;
      font-weight: 400;
    }

    .page-register-my-btn{
      border-radius: 40px;
      background: #D1E67D;
      color: #2A5338;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
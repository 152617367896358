.name-relet-modal {
  width: 600px;
  padding: 30px 20px 40px 20px;
  border-radius: 30px;
  background: #fff;
  box-sizing: border-box;
  @media screen and (max-width: 740px) {
    width:calc(100% - 40px);
    margin-top: 300px;
   }
  .name-relet-modal-content {
    width: 100%;

    .name-relet-modal-close-icon {
      display: flex;
      justify-content: flex-end;
      height: 27px;

      div {
        width: max-content;
        cursor: pointer;
      }
    }

    .name-relet-modal-title {
      font-size: 24px;
      font-weight: 600;
      color: #0e0e0f;
      text-align: center;
    }

    .name-relet-modal-name {
      margin-top: 20px;
      padding: 10px 10px 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 18px;
      border: 1px solid #e6e9ef;

      .name-relet-modal-name-label {
        color: #0e0e0f;
        font-size: 18px;
        font-weight: 600;
      }

      .name-relet-modal-name-copy {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .name-relet-modal-control {
      width: 100%;
      margin-top: 20px;
      padding: 20px;
      border-radius: 18px;
      display: flex;
      box-sizing: border-box;
      border: 1px solid #e6e9ef;

      .name-relet-control-minus-plus {
        width: 40px;
        height: 40px;
        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        cursor: pointer;

        svg {
          filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
          rect {
            fill: #ffffff;
          }
          path {
            stroke: #0e0e0f;
          }
        }

        svg g rect {
          //x: 0;
          //y: -1;
          //width: 53px;
          //height: 54px;
        }
      }

      .name-relet-control-minus-plus-disabled {
        cursor: not-allowed;
      }

      .name-relet-control-input {
        flex: 1 1 auto;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #0e0e0f;
        font-size: 18px;
        font-weight: 600;
        user-select: none;

        input {
          width: 100%;
          box-sizing: border-box;
          height: 40px;
          outline: none;
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          color: #0e0e0f;
          border: none;
          background-color: transparent;
        }
      }
    }

    .name-relet-modal-desc {
      margin-top: 20px;
      padding: 18px 20px;
      border-radius: 18px;
      border: 1px solid #e6e9ef;

      .name-relet-modal-desc-item {
        display: flex;
        justify-content: space-between;

        .name-relet-modal-desc-item-label {
          color: #676968;
          font-size: 14px;
          font-weight: 400;
          user-select: none;
        }

        .name-relet-modal-desc-item-value {
          color: #0e0e0f;
          font-size: 14px;
          font-weight: 400;
          user-select: none;
        }
      }

      .name-relet-modal-desc-item-line {
        width: 100%;
        height: 1px;
        background-color: #e6e9ef;
        margin-bottom: 20px;
      }

      .mb20 {
        margin-bottom: 20px;
      }
    }

    .name-relet-my-domain {
      display: flex;
      padding: 11px 20px;
      margin-top: 20px;
      justify-content: space-between;
      align-items: center;
      color: #c14343;
      text-align: right;
      font-size: 12px;
      font-weight: 400;
      border-radius: 10px;
      background: rgba(239, 218, 218, 0.6);
    }

    .name-relet-submit-btn {
      width: 400px;
      padding: 16px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      background: #d1e67d;
      color: #43532a;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      margin: 20px auto 0;
      @media screen and (max-width: 740px) {
        width:calc(100% - 40px);
       }
    }
  }
}

.mir-button-root {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    path {
      fill: var(--text-color-50);
    }
    circle {
      stroke: var(--text-color-50);
    }
  } 
}
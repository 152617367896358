.page-register {
  width: 100%;
  padding: 140px 0;
  position: relative;
  .register-back-button{
    display: inline-flex;
    padding: 8px 20px 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
    border-radius: 40px;
    background: #202023;
    position: absolute;
    top: 50px;
    left: 20px;
  }
  .page-register-content {
    width: 600px;
    margin: 0 auto;
    padding: 30px 20px 40px;
    border-radius: 30px;
    border: 1px solid #202023;
    position: relative;
    background-color: rgba(14, 14, 15, 0.6);
    /* 模糊 */
    backdrop-filter: blur(15px);
    @media screen and (max-width: 740px) {
      width:calc(100% - 40px);
     }
    .page-register-title {
      text-align: center;
      color: #ffffff;
      font-size: 24px;
      font-weight: 600;
    }

    .page-register-domain {
      display: flex;
      align-items: center;
      margin-top: 20px;
      justify-content: space-between;
      padding: 10px 10px 10px 20px;
      border-radius: 18px;
      border: 1px solid #202023;

      .page-register-domain-name {
        color: #d1e67d;
        font-size: 18px;
        font-weight: 600;
      }

      .page-register-domain-copy {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          border-radius: 10px;
          background: rgba(61, 61, 68, 0.2);
        }
      }
    }

    .page-register-control {
      margin-top: 20px;
      padding: 10px;
      border-radius: 18px;
      border: 1px solid #202023;
      background-color: #0e0e0f;
      display: flex;

      .page-register-control-minus-plus {
        width: 40px;
        height: 40px;
        background-color: #1c1c1f;
        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        cursor: pointer;

        &:hover {
          background-color: #303033;

          svg rect {
            fill: #303033;
          }
        }
      }

      .page-register-control-minus-plus-disabled {
        cursor: not-allowed;
      }

      .page-register-control-input {
        flex: 1 1 auto;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #d1e67d;
        font-size: 18px;
        font-weight: 600;
        user-select: none;

        input {
          width: 100%;
          box-sizing: border-box;
          height: 40px;
          outline: none;
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          color: #d1e67d;
          border: none;
          background-color: #0e0e0f;
        }
      }
    }

    .page-register-details {
      padding: 18px 20px;
      border-radius: 18px;
      border: 1px solid #202023;

      .page-register-details-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .page-register-details-item-label {
          color: #676968;
          font-size: 14px;
          font-weight: 400;
          user-select: none;
        }

        .page-register-details-item-ob-label {
          background-image: -webkit-linear-gradient(
            left,
            #f2b074,
            #db6f61,
            #a53cf2
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          user-select: none;
        }

        .page-register-details-item-value,
        .page-register-details-item-ob-value {
          color: #b6bbb4;
          font-size: 14px;
          font-weight: 400;
          user-select: none;
        }

        .page-register-details-item-ob-value {
          text-decoration: line-through;
        }

        .page-register-details-item-amount {
          color: #d1e67d;
        }
      }

      > div:last-child {
        margin-bottom: 0;
      }
    }

    .page-register-submit {
      width: 400px;
      padding: 16px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      background: #d1e67d;
      color: #43532a;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      margin: 20px auto 0;
      @media screen and (max-width: 740px) {
        width: calc(100% - 40px);
        padding: 16px 0px;
       }
    }
  }
}

.insufficient-balance-modal {
  position: absolute;
  top: calc(50% - 89px);
  left: calc(50% - 170px);
  width: 301px;
  height: 118px;
  padding: 30px 20px 40px 20px;
  border: 1px solid rgba(139, 138, 153, 0.1);
  box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.04);
  border-radius: 30px;
  background-color: #ffffff;
}
.ibm-content{
  display:flex;
 
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  &-header {
    margin-left: auto;
  }
  &-icon {
    margin-left: calc(50% - 22px);
  }
  &-text {
    color: #0E0E0F;
    font-weight: 600;
  }
  &-bridge {
    color: #0E0E0F;
    border-radius: 40px;
    background: #D1E67D;
    width: fit-content;
    padding: 10px 40px;
    margin: auto;
    font-weight: 600;
    margin-top: 30px;
    cursor: pointer;
  }
}
